import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromAuth from '../../reducers/auth/auth.reducers';
import { UserDistributorRoleType } from '../../../core/models/user';

export const getAuthState = createFeatureSelector<fromAuth.State>('auth');

export const getUser = createSelector(getAuthState, fromAuth.getUser);
export const getToken = createSelector(getAuthState, fromAuth.getToken);

export const selectUserId = createSelector(getUser, (user) => {
  return user ? user.id : null;
});

export const selectUserDistributorRoles = createSelector(getUser, (user) => {
  return user ? user.distributorRoles : [];
});

export const selectUserDistributorRole = createSelector(selectUserDistributorRoles, (distributorRoles) => {
  return distributorRoles.length ? distributorRoles[0] : null;
});

export const selectUserDistributor = createSelector(selectUserDistributorRole, (distributorRole) => {
  return distributorRole && distributorRole.distributor;
});

export const selectUserDistributorId = createSelector(selectUserDistributor, (distributor) => {
  return distributor ? distributor.id : 1;
});

export const selectUserDistributorRolesPermissions = createSelector(selectUserDistributorRoles, (distributorRoles) => {
  return distributorRoles.length && distributorRoles[0].role.permissions;
});

export const selectDistributorRolesAllowed = (inputType: string, inputPermissions: Array<string>) => {
  return createSelector(selectUserDistributorRolesPermissions, (roles) => {
    const foundRole = roles.some(
      (r) => r.entityType === inputType && inputPermissions.every((ip) => ip === r.permission)
    );
    const isOwner = roles.some((r) => r.entityType === 'ALL');
    const isAdmin = roles.length === 0;
    return foundRole || isOwner || isAdmin;
  });
};

export const selectDistributorRolesType = createSelector(selectUserDistributorRoles, (distributorRoles) => {
  if (distributorRoles.length) {
    switch (distributorRoles[distributorRoles.length - 1].role.name.toLowerCase()) {
      case 'администратор':
      case 'administrator':
        return UserDistributorRoleType.OWNER;
      case 'офисный сотрудник тип 1':
      case 'ofisniy sotrudnik tip 1':
        return UserDistributorRoleType.TYPE_1;
      case 'офисный сотрудник тип 2':
      case 'ofisniy sotrudnik tip 2':
        return UserDistributorRoleType.TYPE_2;
      default:
        return UserDistributorRoleType.ASM;
    }
  }

  return UserDistributorRoleType.ASM;
});

export const selectUserRoleType = createSelector(getUser, selectDistributorRolesType, (user, distributorRolesType) => {
  if (user.type === 'ADMIN') {
    return UserDistributorRoleType.ADMIN;
  } else {
    return distributorRolesType;
  }
});
