import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { USER_DISTRIBUTOR_URL } from '../../../../../environments/environment';
import { objectToParams } from '../../../../shared/helpers/object.helpers';
import {
  City,
  CityCreatePayload,
  CityRemoveParams,
  CitySearchParams,
  Country,
  CountryCreatePayload,
  CountryRemoveParams,
  CountrySearchParams,
  PostalCodeStreet,
  PostalCodeStreetSearchParams,
  Region,
  RegionCreatePayload,
  RegionRemoveParams,
  RegionSearchParams,
  State,
  StateSearchParams,
} from '../../../models/location';
import { IPageableList } from '../../../models/pageable';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  constructor(private readonly http: HttpClient) {}

  searchCountry(searchCountryParams: Partial<CountrySearchParams> = {}): Observable<IPageableList<Country>> {
    const params = objectToParams(searchCountryParams);
    return this.http.get<IPageableList<Country>>(`${USER_DISTRIBUTOR_URL}/v1/country`, { params });
  }

  saveCountry(payload: CountryCreatePayload): Observable<Country> {
    return this.http.put<Country>(`${USER_DISTRIBUTOR_URL}/v1/country`, payload);
  }

  removeCountry(payload: CountryRemoveParams): Observable<any> {
    const params = objectToParams(payload);
    return this.http.delete<any>(`${USER_DISTRIBUTOR_URL}/v1/country`, { params });
  }

  searchRegion(searchRegionParams: Partial<RegionSearchParams> = {}): Observable<IPageableList<Region>> {
    const params = objectToParams(searchRegionParams);
    return this.http.get<IPageableList<Region>>(`${USER_DISTRIBUTOR_URL}/v1/region`, { params });
  }

  saveRegion(payload: RegionCreatePayload): Observable<Region> {
    return this.http.put<Region>(`${USER_DISTRIBUTOR_URL}/v1/region`, payload);
  }

  removeRegion(payload: RegionRemoveParams): Observable<any> {
    const params = objectToParams(payload);
    return this.http.delete<any>(`${USER_DISTRIBUTOR_URL}/v1/region`, { params });
  }

  searchCity(searchCityParams: Partial<CitySearchParams> = {}): Observable<IPageableList<City>> {
    const params = objectToParams(searchCityParams);
    return this.http.get<IPageableList<City>>(`${USER_DISTRIBUTOR_URL}/v1/city`, { params });
  }

  saveCity(payload: CityCreatePayload): Observable<City> {
    return this.http.put<City>(`${USER_DISTRIBUTOR_URL}/v1/city`, payload);
  }

  removeCity(payload: CityRemoveParams): Observable<any> {
    const params = objectToParams(payload);
    return this.http.delete<any>(`${USER_DISTRIBUTOR_URL}/v1/city`, { params });
  }

  searchPostalCodeStreet(
    searchPostalCodeStreetParams: Partial<PostalCodeStreetSearchParams>
  ): Observable<IPageableList<PostalCodeStreet>> {
    const params = objectToParams(searchPostalCodeStreetParams);
    return this.http.get<IPageableList<PostalCodeStreet>>(`${USER_DISTRIBUTOR_URL}/v1/postal-code-street`, { params });
  }

  searchStates(searchStatesParams: Partial<StateSearchParams>): Observable<Array<State>> {
    const params = objectToParams(searchStatesParams);
    return this.http.get<Array<State>>(`${USER_DISTRIBUTOR_URL}/v1/states`, { params });
  }
}
